$("#sidebar-drawer-toggle").on("click", () => {
  $(".mobile-sidebar-overlap").show();
  $(".mobile-sidebar").show().animate(
    {
      right: "30%",
    },
    1000
  );
});

$(".mobile-sidebar-overlap").on("click", () => {
  $(".mobile-sidebar-overlap").hide();
  $(".mobile-sidebar").animate(
    {
      right: "170%",
    },
    1000
  );
});
